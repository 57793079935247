import {
  CurrencyPipe,
  DatePipe,
  JsonPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
  TitleCasePipe,
} from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  EventEmitter,
  Output,
  inject,
  OnInit,
  DestroyRef,
  output,
  input,
  effect,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import {
  PageLaravel,
  PageRequest,
  Sort,
  SortDirection,
} from '@eapp/clients/core';

import { ColumnType } from '../../models/column-type.enum';
import {
  DEFAULT_PJO_DATATABLE_SETTINGS,
  EappDatatableSettings,
} from '../../models/datatable-settings.model';
import { TableDefinition } from '../../models/table-definition.model';
import { EappDeepPropertyAccessPipe } from '../../pipes/deep-property-access.pipe';
import { EappTruncatePipe } from '../../pipes/truncate.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, filter, tap } from 'rxjs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BasicQuerySearchFilter } from '@eapp/query-search-filters';

@Component({
  selector: 'eapp-emages-laravel-datatable',
  templateUrl: './laravel-datatable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    JsonPipe,
    CurrencyPipe,
    DatePipe,
    FormsModule,
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    NzTableModule,
    NzToolTipModule,
    NzAvatarModule,
    NzIconModule,
    NzInputModule,
    NzDropDownModule,
    NzButtonModule,
    NzCheckboxModule,
    EappDeepPropertyAccessPipe,
    EappTruncatePipe,
    ReactiveFormsModule,
    FlexLayoutModule,
    TitleCasePipe,
  ],
})
export class EappLaravelDatatableComponent implements OnInit {
  @Input() isLoading: boolean;
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  @Input()
  public definition: TableDefinition | null = null;

  @Input()
  // public page: Page<any> | null = null;
  public page: PageLaravel<unknown> | null = null;
  resetSearch = input<string | null>(null);
  private _settings: EappDatatableSettings = DEFAULT_PJO_DATATABLE_SETTINGS;

  @Input()
  public set settings(settings: Partial<EappDatatableSettings>) {
    this._settings = {
      ...DEFAULT_PJO_DATATABLE_SETTINGS,
      ...settings,
    } as EappDatatableSettings;
  }
  public get settings(): EappDatatableSettings {
    return this._settings;
  }

  @Input()
  public defaultSort: Sort = {
    column: 'id',
    direction: SortDirection.Descend,
  } as Sort;

  @Input()
  public actionsContent: TemplateRef<unknown> | null = null;

  @Input()
  public actionsWidth: string | null = '150px';

  @Output()
  public pageChange: EventEmitter<PageRequest> =
    new EventEmitter<PageRequest>();
  private readonly destroyRef = inject(DestroyRef);
  searchTermChange = output<BasicQuerySearchFilter>();

  public ColumnType = ColumnType;
  public SortDirection = SortDirection;

  searchTerm = new FormControl();

  constructor() {
    effect(() => {
      if (this.resetSearch()) {
        this.searchTerm.reset();
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        filter(({ currentPage }) => !!currentPage),
        tap(({ currentPage }) => {
          this.pageIndexChanged(currentPage);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(300),
        tap((value: string) =>
          this.searchTermChange.emit({ query: value?.toLocaleLowerCase() }),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public pageIndexChanged(page: number): void {
    this.pageChange.emit({
      page,
      index: page - 1,
      size: +(this.page?.per_page || 10), //this.page?.current?.size || 10,
      // sort: this.page?.current?.sort
      //   ? ({ ...this.page.current.sort } as Sort)
      //   : null,
    } as PageRequest);
    this.router.navigate(['.'], {
      queryParams: { currentPage: 1 === page ? null : page },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
  }

  public pageSizeChanged(size: number): void {
    this.pageChange.emit({
      index: 0,
      size: size || 10,
      // sort: this.page?.current?.sort
      //   ? ({ ...this.page.current.sort } as Sort)
      //   : this.defaultSort,
    } as PageRequest);
  }

  public sortOrderChanged(column: string, direction: string | null): void {
    const sortDirection: SortDirection | null = direction
      ? direction === 'asc'
        ? SortDirection.Ascend
        : SortDirection.Descend
      : null;

    this.pageChange.emit({
      page: 0,
      index: 0,
      size: +(this.page?.per_page || 10), // this.page?.current?.size || 10,
      sort: sortDirection
        ? { column: column, direction: sortDirection }
        : this.defaultSort,
    });
  }
}
