import { APP_INITIALIZER, makeEnvironmentProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { IconDefinition } from '@ant-design/icons-angular';

import {
  ArrowLeftOutline,
  ArrowRightOutline,
  BlockOutline,
  BuildOutline,
  CloudDownloadOutline,
  ClusterOutline,
  ContainerOutline,
  DashboardOutline,
  DollarOutline,
  DeleteOutline,
  DeleteFill,
  DisconnectOutline,
  EyeInvisibleOutline,
  FileTextOutline,
  FilePdfFill,
  FilePdfOutline,
  FolderAddFill,
  FolderAddOutline,
  FolderViewOutline,
  InboxOutline,
  IdcardOutline,
  MailOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  LockOutline,
  PlusOutline,
  PoweroffOutline,
  RedoOutline,
  SecurityScanOutline,
  SettingOutline,
  SyncOutline,
  StopFill,
  TeamOutline,
  UnlockOutline,
  UsergroupAddOutline,
  UserOutline,
} from '@ant-design/icons-angular/icons';

import { CACHE_SERVICE, SessionCacheService } from '@eapp/clients/core';

import { authenticatedUserInitializer } from './initializers/authenticated-user.initializer';
import { JwtTokenInterceptor } from './interceptors';
import { AuthenticationService } from '../features/identity/features/authentication/services/authentication.service';
import { LoaderInterceptor } from '../features/application/pages/loader/loader.interceptor';

export const jwtTokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtTokenInterceptor,
  multi: true,
};

export const globalLoaderProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoaderInterceptor,
  multi: true,
};

export const windowProvider = {
  provide: Window,
  useValue: window,
};

export const cacheProvider = {
  provide: CACHE_SERVICE,
  useExisting: SessionCacheService,
};

const authenticationUserAppInitializer = {
  provide: APP_INITIALIZER,
  useFactory: authenticatedUserInitializer,
  multi: true,
  deps: [Store, AuthenticationService],
};

export const nzI18NProvider = {
  provide: NZ_I18N,
  useValue: en_US,
};

export const nzGlobalIcons: IconDefinition[] = [
  ArrowLeftOutline,
  ArrowRightOutline,
  BlockOutline,
  BuildOutline,
  CloudDownloadOutline,
  ContainerOutline,
  ClusterOutline,
  DashboardOutline,
  DollarOutline,
  DeleteOutline,
  DisconnectOutline,
  DeleteFill,
  EyeInvisibleOutline,
  FileTextOutline,
  FilePdfFill,
  FilePdfOutline,
  FolderAddFill,
  FolderAddOutline,
  FolderViewOutline,
  IdcardOutline,
  InboxOutline,
  MailOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  LockOutline,
  PoweroffOutline,
  PlusOutline,
  RedoOutline,
  SecurityScanOutline,
  SettingOutline,
  StopFill,
  SyncOutline,
  TeamOutline,
  UnlockOutline,
  UsergroupAddOutline,
  UserOutline,
];

export const provideCoreProviders = () =>
  makeEnvironmentProviders([
    authenticationUserAppInitializer,
    cacheProvider,
    globalLoaderProvider,
    jwtTokenInterceptorProvider,
    nzI18NProvider,
    windowProvider,
  ]);
